import { tokensConfig, type TTokensConfigItem } from './tokens';

interface PackItem {
  price: number;
  tokenReward: number;
  pearlBlackReward: number;
  name: string;
  imagePath: string;
  token: TTokensConfigItem;
  key: string;
  descriptionKey?: string;
}

export const PEARL_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 1000,
    pearlBlackReward: 0,
    name: 'Mini handful of Pearls',
    imagePath: 'pearl-1000',
    token: tokensConfig.pearl,
    key: 'packMini'
  },
  {
    price: 50,
    tokenReward: 5000,
    pearlBlackReward: 0,
    name: 'Standard handful of Pearls',
    imagePath: 'pearl-5000',
    token: tokensConfig.pearl,
    key: 'packStandard'
  },
  {
    price: 100,
    tokenReward: 10000,
    pearlBlackReward: 0,
    name: 'Handful of Pearls',
    imagePath: 'pearl-10000',
    token: tokensConfig.pearl,
    key: 'packHandful'
  },
  {
    price: 250,
    tokenReward: 25000,
    pearlBlackReward: 0,
    name: 'Extra handful of Pearls',
    imagePath: 'pearl-25000',
    token: tokensConfig.pearl,
    key: 'packExtraHandful'
  },
  {
    price: 500,
    tokenReward: 50000,
    pearlBlackReward: 0,
    name: 'Shell Full of Pearls',
    imagePath: 'pearl-50000',
    token: tokensConfig.pearl,
    key: 'packShell'
  },
  {
    price: 1000,
    tokenReward: 100000,
    pearlBlackReward: 500,
    name: 'Pouch Full of Pearls',
    imagePath: 'pearl-100000',
    token: tokensConfig.pearl,
    key: 'packPouch'
  },
  {
    price: 2500,
    tokenReward: 250000,
    pearlBlackReward: 1625,
    name: 'Bag Full of Pearls',
    imagePath: 'pearl-250000',
    token: tokensConfig.pearl,
    key: 'packBag'
  },
  {
    price: 5000,
    tokenReward: 500000,
    pearlBlackReward: 3500,
    name: 'Extra bag Full of Pearls',
    imagePath: 'pearl-500000',
    token: tokensConfig.pearl,
    key: 'packExtraBag'
  },
  {
    price: 10000,
    tokenReward: 1000000,
    pearlBlackReward: 8500,
    name: 'Small Plate of Pearls',
    imagePath: 'pearl-1000000',
    token: tokensConfig.pearl,
    key: 'packSmallPlate'
  },
  {
    price: 15000,
    tokenReward: 1500000,
    pearlBlackReward: 14000,
    name: 'Plate Full of Pearls',
    imagePath: 'pearl-1500000',
    token: tokensConfig.pearl,
    key: 'packPlateFull'
  },
  {
    price: 20000,
    tokenReward: 2000000,
    pearlBlackReward: 19500,
    name: 'Big Plate Full of Pearls',
    imagePath: 'pearl-2000000',
    token: tokensConfig.pearl,
    key: 'packBigPlateFull'
  },
  {
    price: 25000,
    tokenReward: 2500000,
    pearlBlackReward: 25000,
    name: 'Tank Full of Pearls',
    imagePath: 'pearl-2500000',
    token: tokensConfig.pearl,
    key: 'packFullOfPearls'
  }
];

export const COAL_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 10000,
    pearlBlackReward: 0,
    name: 'Mini handful of Coal',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-10000',
    token: tokensConfig.coal,
    key: 'miniHandfulOfCoal'
  },
  {
    price: 100,
    tokenReward: 100000,
    pearlBlackReward: 0,
    name: 'Standard handful of Coal',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-100000',
    token: tokensConfig.coal,
    key: 'standardHandfulOfCoal'
  },
  {
    price: 500,
    tokenReward: 500000,
    pearlBlackReward: 0,
    name: 'Handful of Coal',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-500000',
    token: tokensConfig.coal,
    key: 'handfulOfCoal'
  },
  {
    price: 1000,
    tokenReward: 1000000,
    pearlBlackReward: 0,
    name: 'Small pouch of Coal',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-1000000',
    token: tokensConfig.coal,
    key: 'smallPouchOfCoal'
  },
  {
    price: 2500,
    tokenReward: 2500000,
    pearlBlackReward: 0,
    name: 'Pouch full of Coal',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-2500000',
    token: tokensConfig.coal,
    key: 'pouchFullOfCoal'
  },
  {
    price: 10000,
    tokenReward: 10000000,
    pearlBlackReward: 0,
    name: 'Crate of Coal',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-10000000',
    token: tokensConfig.coal,
    key: 'crateOfCoal'
  }
];

export const GOLD_ORE_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 20,
    pearlBlackReward: 0,
    name: 'Mini handful of Gold Ore',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-20',
    token: tokensConfig.goldOre,
    key: 'miniHandfulOfGoldOre'
  },
  {
    price: 100,
    tokenReward: 200,
    pearlBlackReward: 0,
    name: 'Standard handful of Gold Ore',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-200',
    token: tokensConfig.goldOre,
    key: 'standardHandfulOfGoldOre'
  },
  {
    price: 500,
    tokenReward: 1000,
    pearlBlackReward: 0,
    name: 'Handful of Gold Ore',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-1000',
    token: tokensConfig.goldOre,
    key: 'HandfulOfGoldOre'
  },
  {
    price: 1000,
    tokenReward: 2000,
    pearlBlackReward: 0,
    name: 'Small pouch of Gold Ore',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-2000',
    token: tokensConfig.goldOre,
    key: 'smallPouchOfGoldOre'
  },
  {
    price: 2500,
    tokenReward: 5000,
    pearlBlackReward: 0,
    name: 'Pouch of Gold Ore',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-5000',
    token: tokensConfig.goldOre,
    key: 'pouchOfGoldOre'
  },
  {
    price: 10000,
    tokenReward: 20000,
    pearlBlackReward: 0,
    name: 'Crate of Gold Ore',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-20000',
    token: tokensConfig.goldOre,
    key: 'crateOfGoldOre'
  }
];
