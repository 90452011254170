import pearl from "./pearl.png";
import coal from "./coal.png";
import gold from "./gold.png";
import goldOre from "./goldOre.png";
import iron from "./iron.png";
import ironOre from "./ironOre.png";
import palladium from "./palladium.png";
import platina from "./platina.png";
import platinaOre from "./platinaOre.png";
import rhodium from "./rhodium.png";
import silver from "./silver.png";
import silverOre from "./silverOre.png";
import wood from "./wood.png";
import seasonPoints from "./seasonPoints.png";
import earrings from "./earrings.png";
import necklaces from "./necklaces.png";
import rings from "./rings.png";

import usdt from "./usdt.png";
import blackPearl from "./blackPearl.png";
import testNft from "./testNft.png";


import type { Tokens } from "~/utils/constants";

enum ExtraTokens {usdt = 'usdt'}
const tokens: Record<Tokens | ExtraTokens, string> = { pearl, coal, gold, goldOre, iron, ironOre, palladium, platina, platinaOre, rhodium, silver, silverOre, wood, seasonPoints, blackPearl, testNft, earrings, rings, necklaces, usdt };

export default tokens;